var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.viewport)?_c('div',{staticClass:"interaction"},[_c('banner',{attrs:{"nav-index":2}},[_c('template',{slot:"banner"},[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"center",style:({'will-change': 'transform',transform: `translate3d(0,${_vm.transLateY}px,0)`})},[_c('div',{staticClass:"text"},[_c('h1',[_vm._v(" 交互控制 ")]),_c('div',{staticClass:"line"}),_c('p',[_vm._v(" 交互控制技术主要基于脑机接口，肌机接口，混合接口等前沿交互技术，将个体意念及身体信息进行采集和存储，实现对各类不同意念及身体信息的解码。在AI算法的应用下，实现对意念信息和身体信息监测和检测分类，实现基于人机接口的意念投射及对外界万物的意念控制。在实现了意念解码和再编码的基础上，构建个体的意念与身体感知虚拟数字孪生系统。 ")])])])])])],2),_c('div',{staticStyle:{"position":"relative","z-index":"2","background-color":"#fff"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"footer-box"},[_c('relation')],1)])],1):_c('div',{staticClass:"phone-wrap"},[_c('div',{staticClass:"background"},[_c('div',{staticClass:"nav"},[_vm._m(4),_c('div',{staticClass:"fork",on:{"click":_vm.onList}},[_c('span',{staticClass:"iconfont icon-liebiaoshitucaidan"})])]),_vm._m(5),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.list),expression:"list"}],staticClass:"list"},[_c('phoneNav',{on:{"onHide":_vm.onHide}})],1)]),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_c('phoneFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drone"},[_c('img',{attrs:{"src":require("@/assets/inter_1.gif")}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("非侵入式BCI")]),_c('div',{staticClass:"title"},[_vm._v("脑控交互")]),_c('div',{staticClass:"sub"},[_vm._v("脑机接口与AI算法结合的意念万物互联")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lightadd"},[_c('img',{attrs:{"src":require("@/assets/inter1_1.gif")}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("侵入式BCI")]),_c('div',{staticClass:"title"},[_vm._v("脑机接口系统")]),_c('div',{staticClass:"sub"},[_vm._v("脑机接口与AI算法结合的记忆下载与解码")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"light"},[_c('img',{attrs:{"src":require("@/assets/inter_2.gif")}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("MCI肌控交互")]),_c('div',{staticClass:"sub"},[_vm._v("智能算法赋能EMG可穿戴设备 打造全新体感交互")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"muscle"},[_c('img',{attrs:{"src":require("@/assets/inter_3.gif")}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("HCI 混合交互")]),_c('div',{staticClass:"sub"},[_vm._v("综合运用多种穿戴技术"),_c('br'),_vm._v("实现人体的实时数字孪生")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/phone/phone-logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h1',[_vm._v("交互控制")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" 交互控制技术主要基于脑机接口，肌机接口，混合接口等前沿交互技术，将个体意念及身体信息进行采集和存储，实现对各类不同意念及身体信息的解码。在AI算法的应用下，实现对意念信息和身体信息监测和检测分类，实现基于人机接口的意念投射及对外界万物的意念控制。在实现了意念解码和再编码的基础上，构建个体的意念与身体感知虚拟数字孪生系统。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Bci"},[_c('div',{staticClass:"introduce"},[_c('h3',[_vm._v("非侵入式BCI")]),_c('h3',[_vm._v("脑控交互")]),_c('p',[_vm._v("脑机接口与AI算法结合的"),_c('br'),_vm._v(" 意念万物互联")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"MMci"},[_c('div',{staticClass:"introduce"},[_c('h3',[_vm._v("侵入式BCI")]),_c('h3',[_vm._v("脑机接口系统")]),_c('p',[_vm._v("脑机接口与AI算法结合的"),_c('br'),_vm._v(" 记忆下载与解码")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Mci"},[_c('div',{staticClass:"introduce"},[_c('h3',[_vm._v("MCI肌控交互")]),_c('p',[_vm._v("智能算法赋能EMG可穿戴设备"),_c('br'),_vm._v(" 打造全新体感交互")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Hci"},[_c('div',{staticClass:"introduce"},[_c('h3',[_vm._v("HCI 混合交互")]),_c('p',[_vm._v("综合运用多种穿戴技术"),_c('br'),_vm._v(" 实现人体的实时数字孪生")])])])
}]

export { render, staticRenderFns }