<template>
  <div v-if="viewport" class="interaction">
    <banner :nav-index="2">
      <template slot="banner">
        <div class="banner">
          <div class="center" :style="{'will-change': 'transform',transform: `translate3d(0,${transLateY}px,0)`}">
            <div class="text">
              <h1>
                交互控制
              </h1>
              <div class="line"></div>
              <p>
                交互控制技术主要基于脑机接口，肌机接口，混合接口等前沿交互技术，将个体意念及身体信息进行采集和存储，实现对各类不同意念及身体信息的解码。在AI算法的应用下，实现对意念信息和身体信息监测和检测分类，实现基于人机接口的意念投射及对外界万物的意念控制。在实现了意念解码和再编码的基础上，构建个体的意念与身体感知虚拟数字孪生系统。
              </p>
            </div>
          </div>
        </div>
      </template>
    </banner>
    <div style="position: relative;z-index: 2;background-color: #fff">
      <div class="drone">
        <img src="@/assets/inter_1.gif">
        <div class="content">
          <div class="title">非侵入式BCI</div>
            <div class="title">脑控交互</div>
          <div class="sub">脑机接口与AI算法结合的意念万物互联</div>
        </div>
      </div>


        <div class="lightadd">
            <img src="@/assets/inter1_1.gif">
            <div class="content">
                <div class="title">侵入式BCI</div>
                <div class="title">脑机接口系统</div>
                <div class="sub">脑机接口与AI算法结合的记忆下载与解码</div>
            </div>
        </div>


      <div class="light">
        <img src="@/assets/inter_2.gif">
        <div class="content">
          <div class="title">MCI肌控交互</div>
          <div class="sub">智能算法赋能EMG可穿戴设备 打造全新体感交互</div>
        </div>
      </div>
      <div class="muscle">
        <img src="@/assets/inter_3.gif">
        <div class="content">
          <div class="title">HCI 混合交互</div>
          <div class="sub">综合运用多种穿戴技术<br/>实现人体的实时数字孪生</div>
        </div>
      </div>
      <div class="footer-box">
        <relation></relation>
      </div>
    </div>
  </div>
  <div class="phone-wrap" v-else>
    <div class="background">
      <div class="nav">
        <div class="logo">
          <img src="@/assets/phone/phone-logo.png">
        </div>
        <div @click="onList" class="fork">
          <span class="iconfont icon-liebiaoshitucaidan"></span>
        </div>
      </div>
      <div class="title">
        <h1>交互控制</h1>
      </div>

      <div v-show="list" class="list">
        <phoneNav @onHide="onHide"></phoneNav>
      </div>
    </div>
    <div class="text">
      <p>
        交互控制技术主要基于脑机接口，肌机接口，混合接口等前沿交互技术，将个体意念及身体信息进行采集和存储，实现对各类不同意念及身体信息的解码。在AI算法的应用下，实现对意念信息和身体信息监测和检测分类，实现基于人机接口的意念投射及对外界万物的意念控制。在实现了意念解码和再编码的基础上，构建个体的意念与身体感知虚拟数字孪生系统。
      </p>
    </div>
    <div class="Bci" >
      <div class="introduce" >
      <h3>非侵入式BCI</h3>
      <h3>脑控交互</h3>
        <p>脑机接口与AI算法结合的<br /> 意念万物互联</p>
      </div>
    </div>

    <div class="MMci" >
        <div class="introduce" >
            <h3>侵入式BCI</h3>
            <h3>脑机接口系统</h3>
            <p>脑机接口与AI算法结合的<br />
                记忆下载与解码</p>
        </div>
    </div>

    <div class="Mci" >
      <div class="introduce" >
        <h3>MCI肌控交互</h3>
        <p>智能算法赋能EMG可穿戴设备<br />
          打造全新体感交互</p>
      </div>
    </div>
    <div class="Hci" >
      <div class="introduce" >
        <h3>HCI 混合交互</h3>
        <p>综合运用多种穿戴技术<br />
          实现人体的实时数字孪生</p>
      </div>
    </div>
    <phoneFooter></phoneFooter>
  </div>
</template>

<script>
import banner from '@/components/banner'
import relation from '@/components/footer'
import phoneNav from '@/components/phoneNav/index'
import phoneFooter from "@/components/phoneFooter";

export default {
  name: "interaction.vue",

  components: {
    phoneNav,
    banner,
    relation,
    phoneFooter,
  },
  data() {
    return {
      toTop: 0,
      transLateY: 0,
      viewport: true,
      screenWidth:null,
      list: false,
    }

  },
  watch: {
    screenWidth: function (n, o) {
      if (n <= 1024) {
        this.viewport=false
      } else {
        this.viewport=true
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    this.screenWidth = document.body.clientWidth
    if (document.documentElement.clientWidth < 1280) {
      this.viewport = false
    }
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth

      })()
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {

    onList() {
      setTimeout(() =>{
        this.list = true
      }, 200)

    },

    onHide() {
      setTimeout(() =>{
        this.list = false
      }, 200)

    },

    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop < 900) {
        if (this.toTop <= scrollTop) {
          this.transLateY = scrollTop / 10
        } else {
          this.transLateY = -scrollTop / 10
        }
      }
      this.toTop = scrollTop
    }
  }
}
</script>

<style scoped>

.banner {
}

.center {
  width: 1200px;
  margin: 0 auto;
}

.center .text {
  padding-top: 138px;
}

.center .text h1 {
  font-weight: 600;
  font-size: 54px;
  line-height: 76px;
  color: #FFFFFF;
  font-family: 'PingFang SC';
  font-style: normal;
  letter-spacing: 2px;
}

.center .text .line {
  margin-top: 60px;
  width: 60px;
  height: 6px;
  background-color: #FFFFFF;
}

.center .text p {
  /*width: 1150px;*/
  letter-spacing: 2px;
  margin-top: 60px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 50px;
  color: #FFFFFF;
  opacity: 0.8;
}

.drone {
  line-height: 0;
  position: relative;
}

.drone .content {
  width: 501px;
  position: absolute;
  right: 18.75%;
  top: 54%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.drone .content .title {
  width: 501px;
  height: 90px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 90px;
  color: #111111;
}

.drone .content .sub {
  width: 501px;
  height: 60px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 60px;
  color: #111111;
  margin-top: 20px;
}

.drone img {
  width: 100%;
}

.light {
  line-height: 0;
  position: relative;
}

.light .content {
  position: absolute;
  right: 18.75%;
  top: 43.3%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.light .content .title {
  width: 610px;
  height: 90px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 90px;
  color: #fff;
}

.light .content .sub {
  width: 610px;
  height: 60px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 60px;
  color: #fff;
  margin-top: 20px;
}

.light img {
  width: 100%;

}


.lightadd {
    line-height: 0;
    position: relative;
}

.lightadd .content {
    position: absolute;
    right: 53.75%;
    top: 43.3%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.lightadd .content .title {
    width: 610px;
    height: 90px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    line-height: 90px;
    color: #fff;
}

.lightadd .content .sub {
    width: 610px;
    height: 60px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 60px;
    color: #fff;
    margin-top: 20px;
}

.lightadd img {
    width: 100%;

}

.muscle {
  line-height: 0;
  position: relative;
}

.muscle .content {
  position: absolute;
  left: 13.02%;
  top: 38.89%;
  display: flex;
  flex-direction: column;
}

.muscle .content .title {
  height: 90px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 90px;
  color: #111111;
}

.muscle .content .sub {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 60px;
  color: #111111;
  margin-top: 20px;
}

.muscle img {
  width: 100%;
}
  .phone-wrap {
    font-family: 'PingFang SC';
  }

  .nav {
    display: flex;
    justify-content: space-between;
    padding: 1.14rem 0.6rem 0;
  }

  .nav .logo {
    display: flex;
    align-items: center;
    height: 0.43rem;
  }

  .nav .logo img {
    height: 0.35rem;
  }

  .nav .fork {
    color: #FFFFFF;
    line-height: 0.43rem;
    height: 0.43rem;
  }

  .nav .fork .iconfont {
    font-size: 0.36rem;
  }

  .list {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }

  .background {
    background-image: url("../../assets/phone/interaction.jpg");
    background-repeat: no-repeat;
    /*background-size: 100% 100%;*/
    background-size: cover;
  }

  .background .title {
    padding: 1.6rem 0 2.06rem 0.7rem;
  }

  .background .title h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.46rem;
    line-height: 0.64rem;
    color: #FFFFFF;
    letter-spacing: 0.03rem;
  }

   .phone-wrap .text {
    padding: 0.8rem 0.6rem 0.6rem;
  }

 .phone-wrap .text p {
    font-style: normal;
    font-weight: 400;
    font-size: 0.24rem;
    line-height: 0.5rem;
    color: #111111;
    letter-spacing: 0.02rem;
  }

  .Bci {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.2rem;
    padding: 2.03rem 1.21rem 1.93rem 0;
    background-image: url("../../assets/phone/BCI.gif");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left 30%;
  }

  .Bci .introduce h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.32rem;
    line-height: 0.45rem;
    color: #111111;
    letter-spacing: 0.01rem;
  }

  .Bci .introduce p {
    margin-top: 0.3rem;
    font-style: normal;
    font-weight: 400;
    font-size: 0.24rem;
    line-height: 0.46rem;
    color: #111111;
    letter-spacing: 0.01rem;
  }

.MMci {
    padding: 2rem 0 1.93rem 1.4rem;
    background-image: url("../../assets/phone/inter1_1_phone.gif");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left 52%;
}

.MMci .introduce h3 {
    font-weight: 600;
    font-size: 0.32rem;
    line-height: 0.45rem;
    color: #FFFFFF;
    letter-spacing: 0.01rem;
}

.MMci .introduce p {
    margin-top: 0.3rem;
    font-style: normal;
    font-weight: 400;
    font-size: 0.24rem;
    line-height: 0.46rem;
    color: #FFFFFF;
    letter-spacing: 0.02rem;
}

  .Mci {
    padding: 2rem 0 1.93rem 1.4rem;
    background-image: url("../../assets/phone/MCI.gif");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left 52%;
  }

  .Mci .introduce h3 {
    font-weight: 600;
    font-size: 0.32rem;
    line-height: 0.45rem;
    color: #FFFFFF;
    letter-spacing: 0.01rem;
  }

  .Mci .introduce p {
    margin-top: 0.3rem;
    font-style: normal;
    font-weight: 400;
    font-size: 0.24rem;
    line-height: 0.46rem;
    color: #FFFFFF;
    letter-spacing: 0.02rem;
  }

  .Hci {
    padding: 2.1rem 0.83rem 2.03rem 0;
    background-image: url("../../assets/phone/HCI.gif");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left 76%;
    display: flex;
    justify-content: flex-end;
  }

  .Hci .introduce h3 {
    font-weight: 600;
    font-size: 0.32rem;
    line-height: 0.45rem;
    color: #111111;
    letter-spacing: 0.01rem;
  }

  .Hci .introduce p {
    margin-top: 0.2rem;
    font-style: normal;
    font-weight: 400;
    font-size: 0.24rem;
    line-height: 0.46rem;
    color: #111111;
    letter-spacing: 0.02rem;
  }
</style>
